/* NEW */
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--radius);
  background-repeat: no-repeat;
  z-index: 2;
  color: var(--white);
}

/* ACADEMY BOX */
.academy {
  /* background: linear-gradient(#222222, #141414cc); */
  width: 100%;
  border-radius: 0;
}
.academy .imageWrapper {
  width: 100%;
  min-height: 300px;
}
.academy .imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.academy .content {
  margin-top: -25px;
  padding: 25px 40px 40px;
  background: rgb(60,60,60);
  background: linear-gradient(180deg, rgba(60,60,60,1) 0%, rgba(0,0,0,1) 100%);
}

.academy .content h2 {
  font-size: 22px;
  font-family: "Mont-Bold";
}
.academy .content p {
  padding: 10px 0px;
}
/* ACADEMY BOX END */

/* BLUE BOX */
.blue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 60px 30px;
  background-color: #0041f0;
  border-radius: var(--radius);
  background: linear-gradient(rgba(0, 55, 249, 0.5), rgba(0, 55, 249, 0.2));
  background-repeat: no-repeat;
  z-index: 2;
  color: var(--white);
  border: 2px solid #4494D5;
}

.blue .imageWrapper {
  height: 350px;
  width: 100%;
  position: relative;
}

.blue img {
  width: 100% !important;
  height: 100%;
  object-fit: contain;
}

.blue h3 {
  font-size: 35px;
  font-family: "AlbertSans-Regular";
  font-weight: 500;
  z-index: 2;
  padding-top: 70px;
  text-align: center;
}
/* BLUE BOX END */

/* BLACK BOX */
.black {
  background: linear-gradient(#222222, #141414cc);
  display: flex;
  flex-direction: column;
  padding: 50px;
  height: fit-content;
  justify-content: space-between;
  border-radius: var(--radius);
  background-repeat: no-repeat;
  z-index: 2;
  margin-top: 50px;
}

.black .imageWrapper {
  height: 200px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.black .imageWrapper img {
  width: 100%;
  top: -100px;
  height: 150%;
  position: absolute;
  object-fit: contain;
}

.black h2 {
  padding: 20px 0px;
}
.black ul li {
  list-style: disc;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
}
.black a {
  max-width: 100%;
}
.black .content > div {
  height: 250px;
  overflow: hidden;
  padding-left: 15px;
}
/* BLACK BOX END */

/* DEFAULT BOX */
.default .imageWrapper {
  height: 350px;
  width: 100%;
  overflow: hidden;
  border-radius: var(--radius);
  position: relative;
}
.default .imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.default .content {
  padding: 50px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.default .content h3 {
  padding: 20px 0px;
  text-transform: uppercase;
  font-family: "AlbertSans-Regular";
  font-weight: 400;
  text-transform: uppercase;
  font-size: 28px;
  text-align: center;
}

/* N */
.imageBackground {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: var(--radius);
  min-height: 550px;
  padding: 50px 100px;
  background-repeat: no-repeat;
  text-align: center;
  background-size: cover;
}
.imageBackground h1 {
  font-size: 50px;
  font-family: "AlbertSans-Regular";
  font-weight: 400;
  text-transform: uppercase;
}

.imageBackground a.link {
  color: var(--white);
}

@media only screen and (max-width: 1240px) {
  .imageBackground {
    padding: 40px 60px;
  }
  .imageBackground h1 {
    font-size: 38px;
  }
  .academy .content {
    padding: 15px 20px 20px;
  }
  .academy .content p {
    font-size: 18px;
    line-height: 24px;
    padding: 5px 0;
  }
  .academy .content a {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1024px) {

  .black .content > div {
    padding-left: 0;
  }
  .black ul {
    margin-left: 20px;
  }
  .black ul li {
    font-size: 14px;
    line-height: 26px;
  }
  .black {
    margin-top: 30px;
    padding: 30px 20px 40px;
  }
  .black .content {
    width: 100%;
  }
  .black .imageWrapper img {
    height: 100%;
    top: unset;
    position: unset;
  }
  
  .black a {
    margin-top: 20px;
  }
  .imageBackground {
    padding: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .blue {
    padding: 40px 20px;
    /* height: 420px; */
  }
  .blue img {
    max-width: 100% !important;
    height: unset;
  }
  .blue .imageWrapper {
    height: 280px;
    display: flex;
  }
  .blue h3 {
    font-size: 28px;
    padding-top: 40px;
  }
  .imageBackground {
    min-height: 350px;
    padding: 30px 20px;
  }
  .imageBackground h1 {
    font-size: 28px;
  }
  .imageBackground p {
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
  }
  .academy .content {
    padding: 10px 20px 20px;
  }
  .academy .content h2 {
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .academy .content p {
    padding: 5px 0;
    line-height: 22px;
  }


  .default .content {
    padding: 0px 10px 25px;
  }
  .default .content h3 {
    padding: 15px;
  }
  .default .content p {
    line-height: 22px;
  }
  .default .imageWrapper {
    height: unset;
  }


  .black {
    margin-top: 30px;
    padding: 30px 20px 40px;
  }
  .black .imageWrapper img {
    height: 100%;
    top: unset;
    position: unset;
  }
  
  .black a {
    margin-top: 20px;
  }
}