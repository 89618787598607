@font-face {
  font-family: "AlbertSans-Bold";
  src: local("AlbertSans-Bold"),
    url("./fonts/AlbertSans-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "AlbertSans-Medium";
  src: local("AlbertSans-Medium"),
    url("./fonts/AlbertSans-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "AlbertSans-Regular";
  src: local("AlbertSans-Regular"),
    url("./fonts/AlbertSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "DIN Alternate Bold";
  src: local("DIN Alternate Bold"),
    url("./fonts/DIN Alternate Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Mont-Bold";
  src: local("Mont-Bold"), url("./fonts/Mont-Bold.otf") format("opentype");
  /* font-weight: 700; */
}

:root {
  --white: #fff;
  --black: #000;
  --blue: #0075ff;
  --dark: #0037f9;
  --radius: 30px;
  --gray: #707070;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1a1a1a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
html {
  scroll-behavior: smooth;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  color: var(--white);
  font-family: "AlbertSans-Medium", sans-serif, "Helvetica Neue";
  background-color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

a {
  text-decoration: none;
}
.big_text {
  font-size: 70px;
  font-family: "AlbertSans-Regular";
  font-weight: 400;
  text-transform: uppercase;
  z-index: 2;
  padding-bottom: 20px;
}
.main_container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
}
.space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pbt_50 {
  padding: 50px 0px;
}

.pbt_100 {
  padding: 100px 0px;
}

.end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.black {
  background: linear-gradient(#222222, transparent);
  min-height: 40vh;
  border-radius: var(--radius);
}
.text_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 0px;
  text-align: center;
}
.dots{
  position: relative;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  overflow: hidden;
}
.dots::before {
  content:url('../public/images/dots.svg'); /* with class ModalCarrot ??*/
  position: absolute;
  width: 30%;
  right: 100px;
  top:185px;
}

.arrow-left,
.arrow-right {
  position: absolute;
  top: 50%;
  z-index: 999;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  outline: none;
  border: none;
  color: var(--blue);
  font-size: 18px;
  line-height: 38px;
  text-align: center;
}
.arrow-left {
  left: -50px;
}
.arrow-right {
  right: -50px;
}

@media only screen and (max-width: 1450px) {
  .main_container {
    width: 100%;
    padding: 0px 50px;
    box-sizing: border-box;
  }
  .pbt_100 {
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
  }
  .big_text {
    font-size: 35px;
  }
}

@media only screen and (max-width: 900px) {
  .pbt_50{
    padding:30px 0px;
  }
  .black {
    min-height: 20vh;
  }
  :root {
    --radius: 20px;
  }

  .main_container {
    width: 100%;
    padding: 0px 30px;
    box-sizing: border-box;
  }
  p {
    font-size: 15px;
  }
  .big_text {
    font-size: 25px;
    padding: 0px;
  }
  .space_between {
    align-items: center;
  }
  .pbt_100 {
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
  }
  .end {
    justify-content: flex-start;
  }
  .text_center {
    text-align: left;
    padding: 50px 0px;
  }
}

.xrayScanners {
  width: 100%;
}
.xrayScanners .swiper-wrapper {
  align-items: baseline;
}

.xrayScanners .swiper-slide {
  width: 20% !important;
}

.xrayScanners .swiper-slide:nth-child(2n) {
  width: 25% !important;
}

.xrayScanners .swiper-slide:nth-child(3n) {
  width: 55% !important;
}
.xrayScanners .swiper-slide img {
  width: 100%;
}

.baggageInspection .swiper-slide {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .swiper-slide {
    width: 100%;
  }
  .swiper-slide img {
    max-width: 80%;
  }
  .arrow-left {
    left: -15px;
  }
  .arrow-right {
    right: -15px;
  }
  .dots.home_academy::before{
    content:url('../public/images/dots.svg'); /* with class ModalCarrot ??*/
    position: absolute;
    width: 80%;
    opacity: 0.5;
    right: -205px;
    top:45px;
  }
  .dots.academy::before {
    width: 50%;
    top: unset;
    right: -30px;
    bottom: -30px;
  }
  .xrayScanners .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;
    margin-top: 30px;
  }
  .xrayScanners .swiper-slide,
  .xrayScanners .swiper-slide:nth-child(2n),
  .xrayScanners .swiper-slide:nth-child(3n) {
    width: 100% !important;
  }
}

.swiper-pagination-bullet {
  background: var(--white) !important;
}
.swiper-pagination-bullet-active {
  background: var(--blue) !important;
}

.swiper {
  padding-bottom: 30px !important;
}

.loader {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  background: radial-gradient(circle at 24.1% 68.8%, rgb(15, 15, 15) 0%, rgb(0, 0, 0) 99.4%);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader > div {
  width: 300px;
}

.Dropdown-root.is-open .Dropdown-control {
  border: 1px solid var(--blue) !important;
}