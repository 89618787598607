
.slider_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.slider_container h3 {
  font-size: 56px;
  color: var(--blue);
  font-family: "Mont-Bold";
  line-height: 96px;
}

.slider_container h5 {
  align-self: flex-start;
  font-family: "Mont-Bold";
  font-size: 32px;
  line-height: 54px;
  margin: 40px 0 60px 60px;
}

.points {
  width: 100%;
  margin-top: 60px;
  padding-left: 20px;
}
.points li {
  list-style: disc;
  font-size: 20px;
  line-height: 30px;
}

.explore {
  font-size: 24px;
  color: var(--white);
  border-radius: 35px;
  padding: 18px 50px;
  background-color: var(--blue);
}
.inner_container {
  width: 100%; 
  position: relative; 
  margin-bottom: 100px
}

@media only screen and (max-width: 1024px) {
  .slider_container {
    width: 90%;
  }
  .slider_container h3 {
    font-size: 38px;
    line-height: 48px;
  }
  .slider_container h5 {
    font-size: 26px;
    margin: 20px 0 20px;
  }
  .inner_container {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .slider_container {
    width: 100%;
  }
  .slider_container h3 {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
  .slider_container h5 {
    font-size: 22px;
    line-height: 28px;
    margin: 20px 0 20px 0px;
    text-align: center;
    width: 100%;
  }
  .points {
    margin-top: 40px;
    margin-left: 25px;
  }
  .explore {
    font-size: 20px;
    padding: 18px 0;
    width: 100%;
    text-align: center;
  }
  .inner_container {
    margin-bottom: 40px;
  }
}