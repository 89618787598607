.contact_banner {
  padding: 100px 0;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}
.contact_form {
  padding: 60px 0;
}

.contact_data {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 50px;
  margin-top: 20px;
  margin: 0 auto;
  width: 80%;
}

.contact_data .info_container {
  display: flex;
  flex-direction: column;
}
.contact_data .info:first-child {
  margin-bottom: 20px;
}
.contact_data .info {
  padding: 35px 25px;
  background-color: #222222;
  background: url("../../../public/images/background.svg");
  background-size: cover;
  background-position: center;
  border-radius: 15px;
}

.contact_data .info .heading {
  margin-bottom: 30px;
}

.contact_data .info h4 {
  color: var(--blue);
  font-family: "Mont-Bold";
  font-weight: 400;
  font-size: 23px;
  line-height: 28px;
}
.contact_data .info p {
  font-size: 18px;
  line-height: 25px;
  font-family: "AlbertSans-Medium";
  margin-top: 15px;
}
.contact_data .map {
  border-radius: 15px;
  overflow: hidden;
}
.contact_data .map iframe {
  border: 0;
  height: 100%;
}
.contact_container {
  margin-top: 30px;
  padding-bottom: 250px;
  background: radial-gradient(50% 100%, 100% 10%, var(--blue) -38%, rgba(255,0,0,0.00) 55%);
  background: -webkit-radial-gradient(50% 100%, 100% 10%, var(--blue) -38%, rgba(255,0,0,0.00) 55%);
}

.contact_inner_container {
  width: 80%;
  margin: 0 auto;
}


@media only screen and (max-width: 1450px) {
  .contact_inner_container {
    width: 100%;
    padding: 0px 50px;
  }
}

@media only screen and (max-width: 1240px) {
  .contact_data {
    grid-template-columns: 1fr 1.3fr;
    grid-gap: 20px;
  }
  .contact_data .info {
    padding: 50px 30px;
  }
  .contact_form {
    padding: 60px 0 20px;
  }
}

@media only screen and (max-width: 1450px) {
  .contact_inner_container {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .contact_container {
    margin-top: 50px;
    padding-bottom: 70px;
    background: radial-gradient(50% 100%, 100% 3%, var(--blue) 0%, rgba(255,0,0,0.00) 50%);
    background: -webkit-radial-gradient(50% 100%, 100% 3%, var(--blue) 0%, rgba(255,0,0,0.00) 50%);
  }
  .contact_banner {
    display: none;
  }
  .contact_data {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin-top: 20px;
    width: 100%;
    padding: 0 30px;
  }
  .contact_data .info {
    padding: 30px 25px;
  }
  .contact_data .info h4 {
    font-size: 22px;
    line-height: 26px;
  }
  .contact_data .info p {
    font-size: 18px;
    line-height: 26px;
    margin-top: 15px;
  }
}