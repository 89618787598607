.academy_container p {
  font-size: 20px;
  line-height: 30px;
}
.heading {
  font-size: 56px;
  line-height: 64px;
  font-family: "Mont-Bold";
}

.step_cotaniner {
  width: 860px;
  min-height: 720px;
  margin: 60px auto 0;
  border-radius: 30px;
  background-color: var(--white);
  padding: 40px 50px 140px;
  position: relative;
}

.step_cotaniner .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.step_cotaniner .heading .title {
  display: flex;
  align-items: center
}

.step_cotaniner .heading .back_bttn { 
  border-radius: 5px;
  padding: 0;
  min-width: unset;
  background: transparent;
  box-shadow: none;
  margin-right: 5px;
}
.step_cotaniner .heading .back_bttn svg { 
  width: 34px;
  height: 34px;
}
.step_cotaniner .heading .back_bttn:hover { 
  background-color: #EDEDED;
}

.step_cotaniner .heading h2 {
  font-size: 32px;
  line-height: 42px;
  color: var(--black);
  font-family: "AlbertSans-Medium";
}

.step_cotaniner .heading img {
  width: 77px;
}

.step_cotaniner .inputs {
  margin-top: 40px;
}

.step_cotaniner .input {
  margin-top: 20px;
}

.step_cotaniner .input3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.step_cotaniner .input label,
.step_cotaniner .input input,
.step_cotaniner .input span,
.next_bttn button,
.errorMessage {
  font-size: 16px;
  font-family: "AlbertSans-Regular";
  font-weight: 400;
}

.step_cotaniner .input label {
  color: #8C8C8C;
  margin-left: 20px;
  padding-bottom: 5px;
}

.step_cotaniner .input input,
.select_custom {
  color: var(--black);
  border-radius: 10px !important;
  border: 1px solid #D9D9D9 !important;
  padding: 20px 24px !important;
}

.step_cotaniner .input span,
.errorMessage {
  color: red;
  font-size: 15px;
  margin-left: 20px;
}

.errorMessage {
  margin-left: 0;
}

.select_custom {
  color: #8C8C8C !important;
  font-size: 16px;
  font-family: "AlbertSans-Regular";
  font-weight: 400;
}

.select_custom.active {
  color: var(--black) !important;
}

.step_cotaniner .input input:focus {
  border: 1px solid var(--blue) !important;
}

.select_custom_arrow {
  top: 27px !important;
  right: 20px !important;
  border-color: #D9D9D9 transparent transparent !important;
  border-width: 7px 7px 0 !important
}

.select_custom_menu {
  border-radius: 10px !important;
  border: 1px solid #D9D9D9 !important;
  top: calc(100% + 7px) !important;
}

button.next_bttn { 
  border-radius: 10px;
  min-width: 150px;
  padding: 20px;
  position: absolute;
  right: 50px;
  bottom: 40px;
}

button.next_bttn_loading {
  color: transparent !important;
  pointer-events: none;
}
button.next_bttn_loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from { transform: rotate(0turn); }
  to { transform: rotate(1turn); }
}

.add_more_courses {
  display: flex;
  justify-content: flex-end;
}
.add_more_courses button {
  color: var(--dark);
  background: transparent;
  font-size: 13px;
  font-family: "AlbertSans-Medium";
  font-weight: 500;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  min-width: unset;
  margin-top: 30px;
  cursor: pointer;
}

.add_more_courses button span {
  width: 50px !important;
  height: 50px !important;
  font-weight: 700 !important;
  border-radius: 50% !important;
  background-color: var(--dark) !important;
  background:linear-gradient(0deg, rgba(0,55,249,1) 0%, rgba(0,194,255,1) 150%);
  font-family: "AlbertSans-Bold" !important;
  color: var(--white) !important;
  font-size: 40px !important;
  line-height: 50px;
  text-align: center;
}

.course_container {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 10fr 1fr;
}

.course_container button {
  min-width: unset;
  width: unset;
  background: transparent;
  border-radius: 10px;
  color: #8C8C8C;
  box-shadow: none;
  padding: 0;
}

.success {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.success img {
  width: 77px;
  height: 77px;
}
.success h3 {
  font-size: 30px;
  font-family: "AlbertSans-Medium";
  font-weight: 500;
  color: var(--black);
  margin-top: 40px;
  margin-bottom: 10px;
}

.success p {
  max-width: 430px;
  font-weight: 400;
  font-family: "AlbertSans-Regular";
  font-size: 16px;
  color: #8C8C8C;
  text-align: center;
  line-height: 22px;
  margin-top: 20px;
}

.success a {
  color: #808080;
  background-color: #EDEDED;
  border-radius: 10px;
  width: 150px;
  height: 55px;
  font-size: 16px;
  font-weight: 400;
  font-family: "AlbertSans-Regular";
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.serviceBody{
  margin-top: -80px;
  background-color: var(--black);
  border-radius: var(--radius);
  margin-bottom: 50px;
}
.serviceBody .inner {
  padding: 50px 0;
}

@media only screen and (max-width: 1240px) {
  
  .serviceBody .inner {
    padding: 50px 30px;
  }
  .step_cotaniner {
    width: 100%;
    margin: 20px auto 0;
  }

  .heading {
    font-size: 38px;
    line-height: 44px;
  }
  .academy_container p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 768px) {
  .serviceBody{
    margin-top: -60px;
  }
  .step_cotaniner {
    min-height: 600px;
    padding: 30px 30px 120px;
  }
  .step_cotaniner .heading h2 {
    font-size: 22px;
    line-height: 28px;
    font-family: "AlbertSans-Regular";
  }
  .step_cotaniner .heading img {
    width: 44px;
  }
  .heading {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }
  .academy_container p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .step_cotaniner .inputs {
    margin-top: 30px;
  }
  .step_cotaniner .input {
    margin-top: 15px;
  }

  .step_cotaniner .input input,
  .select_custom {
    border-radius: 8px !important;
    padding: 16px 20px !important;
  }

  .step_cotaniner .input label,
  .step_cotaniner .input input,
  .step_cotaniner .input span,
  .next_bttn button {
    font-size: 15px;
  }

  .step_cotaniner .input label {
    margin-left: 15px;
    padding-bottom: 3px;
  }
  .step_cotaniner .input span,
  .errorMessage {
    font-size: 14px;
    margin-left: 15px;
  }

  .errorMessage {
    margin-left: 0;
  }

  .select_custom {
    font-size: 15px;
  }
  .select_custom_arrow {
    top: 22px !important;
    right: 15px !important;
    border-width: 6px 6px 0 !important
  }

  button.next_bttn { 
    border-radius: 8px;
    min-width: 110px;
    padding: 16px;
    font-size: 16px;
    right: 30px;
  }

  .add_more_courses button span {
    width: 40px !important;
    height: 40px !important;
    font-size: 34px !important;
    line-height: 42px;
  }
  .course_container {
    grid-template-columns: 8fr 1fr;
  }

  .success {
    width: 90%;
  }
  .success h3 {
    text-align: center;
  }
  .step_cotaniner .input3 {
    grid-gap: 15px;
  }
  .step_cotaniner .heading .back_bttn svg { 
    width: 30px;
    height: 30px;
  }
}