footer{
  padding:100px 0px;
  background: radial-gradient(50% 100%, 100% 10%, var(--blue) -38%, rgba(255,0,0,0.00) 55%);
  background: -webkit-radial-gradient(50% 100%, 100% 10%, var(--blue) -38%, rgba(255,0,0,0.00) 55%);
}
footer>div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerText{
  width: 45%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  font-family: "AlbertSans-Regular";
  font-weight: 400;
}
.footerText h1{
  margin-bottom: 80px;
}
footer img{
  width: 100px;
  margin-top: 100px;
}
.copyright {
  margin-top: 20px;
}
@media only screen and (max-width: 1024px) {
  .footerText h1{
    font-size: 40px;
  }
 }

 @media only screen and (max-width: 900px) {
  .footerText{
    width: 100%;
  }
  .footerText h1{
    font-size: 35px;

  }
}