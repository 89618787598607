.anchor{
  background:var(--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  padding:20px 30px;
  font-size: 20px;
  min-width: 350px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.5s;
  z-index: 2;
  white-space: nowrap;
}
.black{
  background-color: #0a0a0a;
  color: var(--blue);
  margin-top: 50px;
}

.transparent{
  background-color: transparent;
  color: var(--white);
  margin-top: 50px;
  min-width: unset;
  padding: 10px 0;
}

.anchor:hover{
  background-color: var(--blue);
}
.black:hover{
  background-color: #1d1c1c;
  color: var(--blue);
}
.transparent:hover{
  background-color: transparent;
}
.mt100{
  margin-top: 100px;
}

@media only screen and (max-width: 1024px) {
  .anchor{
    min-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .anchor{
    padding:15px;
    width: 100%;
    font-size: 16px;
    min-width: 100%;
  }
  .mt100{
    margin:0px;
    margin-top: 30px;
  }
  .xrayScanners {
    width: fit-content;
    min-width: fit-content;
    font-size: 14px;
    border-radius: 10px;
    padding: 10px 12px;
  }
}

.mt15 {
  margin-top: 15px;
}

.mt25 {
  margin-top: 25px;
}

.halfImage {
  width: fit-content;
  margin-top: 35px;
}