.bannerImage {
  background-image: url(../../../public/images/smart_tech_banner.png);
  background-position: center;
  background-size: cover;
}
.serviceBody {
  background-color: var(--black);
  border-radius: var(--radius);
  margin: 0 auto;
  margin-top: -80px;
}

.boxes_container {
  margin-top: -60px;
  margin-bottom: 80px;
}

.boxes_container .boxes {
  width: 80%;
  margin: 30px auto 60px;
}

.boxes_container > img {
  max-width: 100%;
}

.boxes_container .box h3 {
  color: var(--blue);
  font-size: 34px;
  font-family: "Mont-Bold";
  text-align: center;
  line-height: 38px;
  min-height: 76px;
  margin-bottom: 20px;
}

.boxes_container .box p {
  font-size: 21px;
  line-height: 30px;
  font-family: "AlbertSans-Regular";
}

@media only screen and (max-width: 1240px) {
  .boxes_container {
    margin-top: -30px;
    margin-bottom: 40px;
  }
  .boxes_container .boxes {
    width: 90%;
  }
  .boxes_container .box h3 {
    font-size: 30px;
    line-height: 35px;
  }
  
  .boxes_container .box p {
    font-size: 19px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .serviceBody {
    margin-top: -40px;
  }
  .boxes_container {
    margin-top: -40px;
    margin-bottom: 0px;
  }

  .boxes_container .boxes {
    width: 100%;
  }

  .boxes_container .box h3 {
    font-size: 26px;
    line-height: 32px;
    min-height: unset;
  }
  .boxes_container .box p {
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 40px;
    text-align: center;
  }
}