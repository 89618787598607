.serviceBody{
  margin-top: -80px;
  background-color: var(--black);
  border-radius: var(--radius);
}

.serviceBody .title {
  font-size: 40px;
  line-height: 56px;
  font-family: "Mont-Bold";
  margin-bottom: 30px;
}

.line2 {
  position: relative;
  overflow: hidden;
}
.line2 > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}
.line2 h3 {
  font-family: "Mont-Bold";
  font-size: 60px;
  line-height: 90px;
}
.line2 p {
  color: var(--blue);
  font-family: "DIN Alternate Bold";
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 20px;
}
.line2 h3 span {
  color: var(--blue);
}
.line2 img {
  z-index: 4;
  max-width: 80%;
}
.line2::after{
  content: url("../../../public/images/lines/line04-gray.svg"); /* with class ModalCarrot ??*/
  position: absolute;
  width: 121%;
  left: -8%;
  top: 55%;
  z-index: 0;
}

.line{
  padding:50px 0px;
}

.gridBoxes {
  margin-top: 80px;
}

.container {
  background: rgb(255,255,255);
  background: linear-gradient(160deg, rgba(255,255,255,0.08) 0%, rgba(0,0,0,1) 85%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}
.certifications {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  position: relative;
  width: 100%;
}
.certifications:before,
.certifications:after {
  content: "";
  position: absolute;
  top: 50%;
  height: 2px;
  background-color: var(--gray);
  width: calc(50% - 140px);
}
.certifications1:before,
.certifications1:after {
  width: calc(50% - 70px);
}
.certifications3:before,
.certifications3:after {
  width: calc(50% - 210px);
}
.certifications4:before,
.certifications4:after {
  width: calc(50% - 280px);
}

.certifications:before {
  left: 0;
}
.certifications:after {
  right: 0;
}

.certifications img {
  margin: 0 15px;
  width: 98px;
  max-height: 98px;
}

.text_holder {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text_holder h3 {
  color: var(--blue);
  margin-top: 30px;
  margin-bottom: -15px;
}

.text_holder p {
  font-family: "AlbertSans-Regular";
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  width: 85%;
  margin: 0 auto;
  margin-top: 25px;
}

.text_holder img {
  max-width: 100%;
  border-radius: 15px;
  margin-top: 60px;
}

.boxes {
  display: flex;
  width: 90%;
  margin: 110px auto 50px;
}

.boxes .item {
  width: 50%;
  display: flex;
  margin: 0 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.boxes .item .item_holder {
  width: 100%;
  padding: 30px;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  background: linear-gradient(0deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.2) 100%);
  height: 100%;
}
.boxes .item_holder h5.title,
.boxes .item_holder p.label,
.boxes .item_holder p.values {
  text-align: center;
}

.boxes .item_holder h5.title {
  color: var(--blue);
  font-family: "DIN Alternate Bold";
  font-size: 28px;
  line-height: 32px;
}

.boxes .item_holder p {
  margin: 0;
  width: 100%;
  margin: 8px 0;
}

.boxes .item_holder p.label {
  margin: 10px 0;
}
.boxes .item a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--blue);
  font-size: 22px;
  line-height: 32px;
  font-family: "DIN Alternate Bold";
}

.boxes .item a img {
  width: 40px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .serviceBody {
    margin-top: -60px;
  }
  .line2 > div {
    padding-bottom: 35px;
  }
  .line2 h3 {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }
  .line2 p {
    font-size: 22px;
    margin: 10px 0 20px;
  }
  .line2 img {
    max-width: 100%;
  }
  .line2::after {
    top: 65%;
  }

  .container {
    padding-top: 40px;
  }
  .text_holder {
    margin-top: 20px;
  }
  .text_holder p {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  }

  .boxes {
    width: 100%;
    margin: 60px auto 0px;
    flex-direction: column;
  }
  .boxes .item {
    width: 100%;
    margin: 0 0 30px;
  }
  .boxes .item a img {
    margin-top: 20px;
    width: 25px;
  }
  .boxes .item a span {
    font-size: 16px;
  }
  .boxes .item_holder {
    padding: 30px 15px;
  }
  .boxes .item_holder h5.title {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .boxes .item_holder p {
    font-size: 16px;
    margin: 6px 0;
    line-height: 20px;
  }
}