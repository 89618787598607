.intro_container {
  padding: 80px 0 120px;
  border-bottom: 2px solid var(--gray);
  border-bottom-right-radius: 150px;
  background: linear-gradient(170deg, rgba(0,0,0,1) 50%, rgba(255,255,255,0.07) 100%);
}

.intro_container .sub_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.intro_container .sub_banner .img {
  width: 30%;
}
.intro_container .sub_banner img {
  max-width: 100%;
}

.intro_container .sub_banner .text {
  width: calc(70% - 100px);
  margin-left: 100px;
}

.intro_container .text h2 {
  font-size: 45px;
  color: var(--blue);
  line-height: 74px;
  font-family: "Mont-Bold";
}

.intro_container .text h3 {
  font-size: 42px;
  line-height: 49px;
  margin-top: 10px;
}

.intro_container .text p {
  font-size: 21px;
  margin-top: 40px;
  line-height: 28px;
  font-family: "AlbertSans-Regular";
}

@media only screen and (max-width: 1240px) {
  .intro_container {
    padding: 60px 0 60px;
    border-bottom-right-radius: 100px;
  }
  .intro_container .sub_banner {
    width: 100%;
  }
  .intro_container .sub_banner .text {
    margin-left: 50px;
    width: calc(70% - 50px);
  }
  .intro_container .text h2 {
    font-size: 38px;
    line-height: 48px;
  }
  .intro_container .text h3 {
    font-size: 28px;
    line-height: 36px;
  }
  .intro_container .text p {
    font-size: 19px;
    margin-top: 30px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 768px) {
  .intro_container {
    padding: 60px 0 60px;
    border-bottom-right-radius: 80px;
  }
  .intro_container .sub_banner {
    flex-direction: column;
    width: 100%;
  }
  .intro_container .sub_banner .img {
    width: 50%;
  }
  .intro_container .sub_banner .text {
    width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }
  .intro_container .text h2 {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
  .intro_container .text h3 {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }
  .intro_container .text p {
    font-size: 18px;
    line-height: 23px;
    margin-top: 20px;
    text-align: center;
  }
}

.box {
  width: 60%;
  margin: 40px auto 0;
  padding: 50px 70px;
  background-color: #000;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  background: linear-gradient(0deg, rgba(255,255,255,0.1) 30%, rgba(0,0,0,0.1) 100%);
}
.box h2 {
  color: var(--blue);
  text-align: center;
  font-size: 45px;
  font-family: "Mont-Bold";
  line-height: 56px;
  margin-bottom: 35px;
}
.box p {
  margin-top: 20px;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  font-family: "AlbertSans-Regular";
}


@media only screen and (max-width: 1240px) {
  .box {
    width: 85%;
  }
  .box h2 {
    font-size: 34px;
    line-height: 44px;
  }
  .box p {
    margin-top: 15px;
    font-size: 20px;
    line-height: 26px;
  } 
}

@media only screen and (max-width: 768px) {
  .box {
    margin: 20px;
    width: calc(100% - 40px);
    padding: 30px 20px 40px;
  }
  .box h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .box p {
    font-size: 18px;
    line-height: 22px;
  }
}