.grid1 {
  display: grid;
  grid-template-columns: 1fr;
}
.grid2 {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.grid12 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
}
.grid3,
.gridCustomTrio {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  grid-row-gap: 30px;

}
.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.grid21 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
}
.grid61 {
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-gap: 20px;
}

.grid12 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
  align-items: center;
  height: 100%;
}

.grid6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}
.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}
@media only screen and (max-width: 960px) {
  .grid3{
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 900px) {
  .grid3{
    grid-template-columns: 1fr 1fr;
  }
  .gridCustomTrio {
    grid-gap: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .gridCustom2,
  .grid61,
  .gridBanner12,
  .grid3,
  .grid2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .grid12 {
    display: grid;
    grid-gap: 10px;
    position: relative;
    align-items: center;
    grid-template-columns: 1fr 2fr !important;
  }

  .grid4 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .grid6 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
  .grid5 {
    grid-template-columns: repeat(3, 1fr);
  }
  .gridCustomTrio {
    grid-gap: 15px;
  }
}
