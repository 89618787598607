.serviceBody{
  margin-top: -80px;
  background-color: var(--black);
  border-radius: var(--radius);
}

.serviceBody .title {
  font-size: 40px;
  line-height: 56px;
  font-family: "Mont-Bold";
  margin-bottom: 30px;
}

.line{
  padding:50px 0px;
  position: relative;
  overflow: hidden;
}
/* .line::before{
  content: url("../../../public/images/lines/line02-gray.svg");
  position: absolute;
  width: 100%;
  top: -90%;
  left: -25%;
  z-index: 0;
} */
.line2{
  position: relative;
  overflow: hidden;
  border-top: 2px solid var(--gray);
  background: linear-gradient(25deg, rgba(0,0,0,1) 50%, rgba(255,255,255,0.03) 100%);
  border-radius: 0 20%/90px 90px 0;
}
/* .line2::after{
  content: url("../../../public/images/lines/line04-gray.svg"); 
  position: absolute;
  width: 121%;
  left: -8%;
  top: 0%;
  z-index: 0;
} */

.gridBoxes {
  margin-top: 80px;
}
.intro_banner {
  width: 90%;
  margin: 0 auto;
}
.intro_banner > div {
  width: 45%;
}
.intro_banner > div:first-child {
  width: 55%;
}
.intro_banner > div img {
  width: 100%;
}
.intro_banner h2 {
  color: var(--white) !important;
  text-align: left !important;
}

@media only screen and (max-width: 768px) {
  .line2{
    background: linear-gradient(45deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0.06) 100%);
    border-radius: 0 15%/30px 30px 0
  }
  .serviceBody {
    margin-top: 0;
  }
  .removeBanner {
    padding: 0;
  }
  .line{
    padding: 110px 0 50px;
  }
  .serviceBody .title {
    font-size: 28px;
    line-height: 36px;
  }
  .gridBoxes {
    margin-top: 20px;
  }
  .intro_banner {
    width: 100%;
    margin: 0 auto;
  }
  .intro_banner > div,
  .intro_banner > div img,
  .intro_banner > div:first-child {
    width: 100%;
  }
  .intro_banner h2 {
    color: var(--white) !important;
    text-align: left !important;
  }
}