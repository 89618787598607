input {
  border: none;
  border-bottom: 2px solid #424242;
  background-color: transparent;
  outline: none;
  width: 100%;
  font-size: 17px;
  padding-bottom: 10px;
  color: var(--white);
}
input::placeholder {
  color: #464646;
}
.inputWrapper {
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}
.inputSingle {
  padding-bottom: 40px;
  grid-gap: 50px;
}
textarea {
  border: 2px solid #424242;
  border-radius: 13px;
  font-size: 17px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 20px;
  outline: none;
  color: var(--white);
  resize: none;
}
.inputWrapper .inputSingle:last-child {
  padding-bottom: 0px;
}
.textarea {
  display: flex;
  flex-direction: column;
}
label {
  color: #464646;
  padding-bottom: 10px;
  display: flex;
}

.contactWrapper {
  position: relative;
  overflow: hidden;
  padding:100px 0px 60px;
}

.contactWrapper.line::after{
  content: url("../../../public/images/lines/line05.svg"); /* with class ModalCarrot ??*/
  position: absolute;
  width: 100%;
  left: 0%;
  top: 0%;
  z-index: -1;
}
.contactWrapper>div{
  width: 80%;
  margin: auto;
}
.contactWrapper>div.small{
  width: 60%;
}
.contactWrapper h1 {
  font-family: "AlbertSans-Regular";
  font-weight: 500;
  text-transform: uppercase;
}
.contactWrapper form {
  background-color: #222222;
  background: url("../../../public/images/background.svg");
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  padding: 70px;
  margin: 30px 0px 0px;
}

form button {
  background: linear-gradient(0deg, rgba(0,55,249,1) 0%, rgba(0,194,255,1) 150%);
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  color: var(--white);
  padding:  30px;
  font-size: 21px;
  min-width: 300px;
  outline: none;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.5s;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-family: "AlbertSans-Bold";
}

form a{
  color: #5587c3;
  padding:0px 5px;
  display: flex;
}
.checkbox{
  cursor: pointer;
  display: block;
  margin-bottom: 30px;
  padding-left: 35px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  color: #424242;
  font-size: 15px;
}
.checkbox input{
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkbox span:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ span:after {
  display: block;
}

.checkbox span:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox span{
  border: 2px solid #424242;
  border-radius: 4px;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 25px;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 70px;
}
.heading img {
  width: 68px;
}

.error {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 25px;
}

.success {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 25px;
  color: green;
}

.label_font {
  font-size: 16px;
  line-height: 20px;
}
.label_font a {
  display: inline;
}

@media only screen and (max-width: 1240px) {
  .contactWrapper>div.small{
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .contactWrapper>div, 
  .contactWrapper>div.small{
    width: 100%;
    padding: 0px 30px;
  }
  .heading {
    padding: 0 10px;
  }
  .contactWrapper h1 {
    font-size: 28px;
  }
  .contactWrapper form {
    margin-top: 20px;
    padding: 45px 30px;
  }
  .inputWrapper {
    grid-template-columns: 1fr;
    grid-gap: 40px
  }
  form button {
    padding: 20px;
    min-width: 100%;
    border-radius: 15px;
  }
  .heading img {
    width: 48px;
  }
}