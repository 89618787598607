header {
  top: 65px;
  width: 100%;
  position: absolute;
  z-index: 2;
}
.headerFixed {
  /* top: 0; */
  /* position: fixed; */
  background: #000;
  background: linear-gradient(to bottom, #0037f9, #00c6ff);
  padding: 12px 0px 8px;
  border-bottom-right-radius: 26px;
  border-bottom-left-radius: 26px;
  animation: smooth-appear .3s ease forwards;
}

@keyframes smooth-appear {
  from { top: -65px; opacity: 0; }
  to { top: 0; opacity:1; }
}

header .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .content img {
  width: 280px;
}

header ul {
  display: flex;
  justify-content: flex-end;
}

header li {
  display: inline-block;
}

header li a {
  display: block;
  padding: 10px 30px;
  color: var(--white);
}

/* MOBILE */
.headerMobileTop {
  display: flex;
  position: fixed;
  top: 0;
  padding: 20px 30px 15px;
  width: 100%;
  justify-content: space-between;
  z-index: 9999;
}
.headerMobileTop a {
  width: 180px;
}
.headerMobileTop a img {
  width: 100%;
}

.mobileHeader {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--black);
  height: 100vh;
  transition: all 0.2s ease-in-out;
  -webkit-animation: movedown 0.5s linear forwards;
  -moz-animation: movedown 0.5s linear forwards;
  -ms-animation: movedown 0.5s linear forwards;
  -o-animation: movedown 0.5s linear forwards;
  animation: movedown 0.5s linear forwards;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  padding-top: 90px;
}
.openMenu {
  opacity: 1;
  visibility: visible;
}
.mobileHeader ul {
  list-style: none;
  padding: 20px 0px;
}
.mobileHeader li {
  padding: 10px 0px;
  -webkit-animation: translate-title 2s linear;
  -webkit-animation-fill-mode: forwards;
  animation: translate-title 2s linear;
  animation-fill-mode: forwards;
  text-align: left;
  margin: 0;
  padding: 0;
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
  transition-delay: 0.05s;
}
.mobileHeader li:nth-child(2n) {
  transition-delay: 0.07s;
}
.mobileHeader li:nth-child(3n) {
  transition-delay: 0.09s;
}
.fadeIn {
  opacity: 1 !important;
  transform: translateX(10px) !important;
}
.mobileHeader a {
  color: var(--white);
  text-decoration: none;
  font-size: 24px;
  padding: 10px 0px;
  display: flex;
}
.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  header {
    display: none;
  }
}
