.halfImageContainer h2 {
  color: var(--white) !important;
  font-size: 35px !important;
  line-height: 43px !important;
}

.courses h2 {
  color: var(--white) !important;
  font-size: 99px !important;
  line-height: 107px !important;
  text-align: left !important;
}

.academy_section {
  margin-top: 60px;
  margin-bottom: 80px;
}
.academy_section .service_layout {
  margin-top: 60px;
}

@media only screen and (max-width: 1240px) {
  .courses h2 {
    font-size: 50px !important;
    line-height: 57px !important;
  }
}

@media only screen and (max-width: 768px) {
  .academy_section h1 {
    text-align: center;
  }
  .academy_section p {
    margin-top: 30px;
    text-align: center;
  }
  .academy_section .service_layout {
    margin-top: 30px;
  }
}