.iconBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.iconBox div {
  padding: 50px;
  border-radius: var(--radius);
  background-color: #f5f5f5;
  box-sizing: border-box;
  width: 100%;
  height: 220px;
  width: 220px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.iconBox img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.iconBox h3 {
  padding: 20px 0px;
  text-transform: uppercase;
  font-family: "AlbertSans-Regular";
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}


@media only screen and (max-width: 1400px) {
  .iconBox div{
    padding: 40px;
    height: 180px;
  }
}
@media only screen and (max-width: 1240px) {
  .iconBox{
    padding-bottom: 0px;
  }
  .iconBox div{
    padding:30px;
    height: 105px;
    width: 100%;
  }
  .iconBox h3{
    font-size: 13px;
  }
}
