.half_image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
.half_image.custom {
  margin-top: -60px;
}
.half_image.reverse {
  flex-direction: row-reverse;
}

.half_image .image {
  width: 60%;
}
.half_image .image img {
  width: 100%;
}
.half_image .text {
  margin-left: 100px;
  width: calc(40% - 100px);
}

.half_image.reverse .text {
  margin-left: 0;
  margin-right: 100px;
}

.half_image .text h2 {
  font-size: 45px;
  color: var(--blue);
  line-height: 74px;
  font-family: "Mont-Bold";
}
.half_image .text h3 {
  font-size: 42px;
  line-height: 49px;
  margin-top: 10px;
}
.half_image .text p {
  font-size: 21px;
  margin-top: 40px;
  line-height: 28px;
  font-family: "AlbertSans-Regular";
}
.half_image.reverse .text h2 {
  text-align: left;
}

@media only screen and (max-width: 1240px) {
  .link {
    display: flex;
    align-items: center;
  }
  .half_image .image {
    width: 50%;
  }
  .half_image .text {
    margin-left: 60px;
    width: calc(50% - 60px);
  }
  .half_image.reverse .text {
    margin-left: 0;
    margin-right: 60px;
  }
  .half_image .text h2 {
    font-size: 28px !important;
    line-height: 40px !important;
    margin-top: 15px;
  }
  .half_image .text p {
    font-size: 18px;
    margin-top: 20px;
    line-height: 23px;
  }
}

@media only screen and (max-width: 960px) {
  .half_image .image,
  .half_image .text {
    width: 100%;
  }
  .half_image {
    margin-top: 60px;
  }
  .half_image,
  .half_image.reverse {
    flex-direction: column;
  }

  .half_image img {
    max-width: 100%;
  }
  .half_image.custom {
    margin-top: -30px;
  }

  .half_image .text,
  .half_image.reverse .text {
    margin-left: 0;
    margin-right: 0;
  }

  .half_image .text h2 {
    font-size: 28px !important;
    line-height: 40px !important;
    margin-top: 15px;
    text-align: center !important;
  }
  .half_image .text p {
    font-size: 18px;
    margin-top: 20px;
    line-height: 23px;
    text-align: center;
  }
}