.about_banner {
  padding: 100px 0;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

.about_container {
  margin-top: 30px;
  padding-bottom: 200px;
  background: radial-gradient(50% 100%, 100% 10%, var(--blue) -38%, rgba(255,0,0,0.00) 55%);
  background: -webkit-radial-gradient(50% 100%, 100% 10%, var(--blue) -38%, rgba(255,0,0,0.00) 55%);
}

.intro_container {
  padding: 60px 0 120px;
}

.intro_container .sub_banner {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  min-height: 450px;
}

.intro_container .sub_banner .img {
  width: 380px;
  height: 380px;
  background: url(../../../public/images/icons/logo-outline-color.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.intro_container .sub_banner .text {
  margin-right: 100px;
}

.intro_container .text h2 {
  font-size: 52px;
  line-height: 56px;
  margin-bottom: 30px;
  font-family: "Mont-Bold";
}
.intro_container .text p {
  font-size: 21px;
  margin-top: 20px;
  line-height: 28px;
  font-family: "AlbertSans-Regular";
}

.large_banner {
  position: relative;
}
.large_banner img {
  max-width: 100%;
  object-fit: cover;
}
.large_banner .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.large_banner .text h2 {
  font-size: 86px;
  font-family: "Mont-Bold";
  text-align: center;
} 

.boxes {
  display: flex;
  width: 100%;
  margin: 110px auto 50px;
}
.boxes .box {
  width: 50%;
  display: flex;
  margin: 0 20px;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  padding: 50px;
  border-bottom-left-radius: 42px;
  border-bottom-right-radius: 42px;
  background: linear-gradient(0deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.2) 70%);
}

.boxes .box h3,
.boxes .box p {
  text-align: center;
}

.boxes .box h3 {
  font-family: "Mont-Bold";
  font-size: 53px;
  line-height: 57px;
  margin: 20px 0 10px;
}

.boxes .box p {
  margin: 0;
  width: 100%;
  margin: 8px 0;
  font-family: "AlbertSans-Regular";
  font-size: 18px;
  line-height: 22px;
}

.boxes .box p {
  margin: 10px 0;
}
.boxes .box img {
  width: 88px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1024px) {
  .about_container {
    max-width: 100%;
    overflow: hidden;
  }
  .intro_container {
    padding: 60px 0 80px;
  }
  .intro_container .sub_banner {
    grid-template-columns: 1fr;
    min-height: unset;
    position: relative;
  }
  .intro_container .sub_banner .text {
    margin-right: 0;
  }
  .intro_container .sub_banner .img {
    position: absolute;
    top: 50%;
    right: -50%;
    transform: translateY(-50%);
    background: url(../../../public/images/icons/logo-outline.svg);
    z-index: -1;
  }
  .intro_container .text h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
    text-align: center;
  }
  .intro_container .text p {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .large_banner .text h2 {
    font-size: 52px;
  } 

  .boxes .box {
    margin: 0 15px;
    padding: 30px;
    border-bottom-left-radius: 34px;
    border-bottom-right-radius: 34px;
  }
  
  .boxes .box h3 {
    font-size: 38px;
    line-height: 46px;
  }
  
  .boxes .box p {
    font-size: 16px;
    line-height: 20px;
  }
  .boxes .box img {
    width: 68px;
  }
}

@media only screen and (max-width: 768px) {
  .about_container {
    margin-top: 50px;
    padding-bottom: 50px;
    max-width: 100%;
    overflow: hidden;
    background: radial-gradient(50% 100%, 100% 3%, var(--blue) 0%, rgba(255,0,0,0.00) 50%);
    background: -webkit-radial-gradient(50% 100%, 100% 3%, var(--blue) 0%, rgba(255,0,0,0.00) 50%);
  }
  .about_banner {
    display: none;
  }

  .large_banner img {
    min-height: 200px;
  }
  .large_banner .text h2 {
    font-size: 32px;
  } 

  .boxes {
    flex-direction: column;
  }
  .boxes .box {
    width: 100%;
    margin: 0px;
    padding: 30px;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
  }
  
  .boxes .box h3 {
    font-family: "Mont-Bold";
    font-size: 28px;
    line-height: 32px;
    margin: 20px 0 10px;
  }
  
  .boxes .box p {
    margin: 8px 0;
    font-size: 16px;
    line-height: 20px;
  }
  
  .boxes .box img {
    width: 68px;
  }
} 