.serviceBody {
  background-color: var(--black);
  border-radius: var(--radius);
  margin: 0 auto;
  margin-top: -80px;
}
.serviceBody p {
  font-size: 21px;
  text-align: center;
  line-height: 28px;
  font-family: 'AlbertSans-Regular';
  width: 93%;
  margin: 30px auto 0;
}
.serviceBody .center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}
.serviceBody img {
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 7px;
}
.serviceBody .spacing {
  margin: 80px 0 60px;
}
.shield_container {
  display: flex;
  padding-top: 80px;
  align-items: center;
  flex-direction: column;
}

.shield_container img {
  width: 320px;
  margin-top: 0;
  border-radius: 0;
}

.shield_container p {
  margin-top: 40px;
  max-width: 1100px;
  font-size: 32px;
  color: var(--blue);
  line-height: 54px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 40px;
}

.eye_container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;
}
.eye_container img {
  margin-bottom: 80px;
  width: 920px;
}
.eye_container h4 {
  color: var(--blue);
  font-size: 45px;
  line-height: 74px;
}
.eye_container h3 {
  font-size: 55px;
  color: var(--white);
  text-align: center;
  line-height: 74px;
}
.two_images {
  display: flex;
  width: 100%;
  height: unset;
  margin-top: 60px;
  margin-bottom: 40px;
}
.two_images img {
  width: calc(50% - 30px);
  margin: 0 15px;
}

@media only screen and (max-width: 960px) {
  .eye_container img {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .serviceBody .center {
    margin-bottom: 0px;
  }
  .serviceBody p {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }

  .serviceBody img {
    width: 100%;
  }

  .two_images {
    margin-top: 40px;
    margin-bottom: 15px;
  }
  .two_images img {
    margin: 0 10px;
    width: calc(50% - 20px);
  }
  .shield_container {
    padding-top: 200px;
  }
  .shield_container img {
    width: 60%;
  }
  .shield_container p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .removeBanner {
    padding: 0;
  }

  .eye_container {
    margin-top: 60px;
    margin-bottom: 20px;
  }
  .eye_container img {
    margin-bottom: 40px;
  }
  .eye_container h4 {
    font-size: 28px;
    text-align: center;
    line-height: 38px;
  }
  .eye_container h3 {
    font-size: 22px;
    line-height: 34px;
    margin-top: 20px;
  }

  .serviceBody .spacing {
    margin: 60px 0 40px;
  }
}