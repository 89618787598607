.bannerImage {
  background-image: url(../../../public/images/electrical_services_banner.png);
  background-position: center;
  background-size: cover;
}
.serviceBody {
  background-color: var(--black);
  border-radius: var(--radius);
  margin: 0 auto;
  margin-top: -80px;
}

.half_image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
.half_image.custom {
  margin-top: -60px;
}
.half_image.reverse {
  flex-direction: row-reverse;
}

.half_image .text {
  margin-left: 100px;
}

.half_image.reverse .text {
  margin-left: 0;
  margin-right: 100px;
}

.half_image .text h2 {
  font-size: 45px;
  color: var(--blue);
  line-height: 74px;
  font-family: "Mont-Bold";
  text-align: right;
}
.half_image .text h3 {
  font-size: 42px;
  line-height: 49px;
  margin-top: 10px;
}
.half_image .text p {
  font-size: 21px;
  margin-top: 40px;
  line-height: 28px;
  font-family: "AlbertSans-Regular";
}
.half_image.reverse .text h2 {
  text-align: left;
}

.large_banner {
  width: 100%;
  position: relative;
  margin: 120px 0 80px;
}

.large_banner > img {
  width: 100%;
}

.large_banner .content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1100px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.large_banner .content p {
  margin: 60px;
  font-size: 24px;
  font-family: "AlbertSans-Regular";
  line-height: 34px;
  text-align: center;
}


@media only screen and (max-width: 1240px) {
  .large_banner .content {
    width: 90%;
  }
  .large_banner .content p {
    margin: 40px;
    font-size: 20px;
    line-height: 26px;
  }
  .large_banner .content img {
    width: 55px;
  }
  .large_banner .content img:first-child {
    width: 65px;
  }
}

@media only screen and (max-width: 768px) {
  .serviceBody {
    margin-top: -40px;
  }
  .half_image {
    margin-top: 60px;
  }
  .half_image,
  .half_image.reverse {
    flex-direction: column;
  }

  .half_image img {
    max-width: 100%;
  }
  .half_image.custom {
    margin-top: -30px;
  }

  .half_image .text,
  .half_image.reverse .text {
    margin-left: 0;
    margin-right: 0;
  }

  .half_image .text h2 {
    font-size: 32px;
    line-height: 44px;
    margin-top: 15px;
    text-align: center !important;
  }
  .half_image .text p {
    font-size: 18px;
    margin-top: 20px;
    line-height: 23px;
    text-align: center;
  }

  .large_banner {
    width: 100%;
    position: relative;
    margin: 80px 0 60px;
  }

  .large_banner > img {
    max-width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .large_banner .content {
    width: 100%;
  }
  .large_banner .content img {
    width: 45px;
  }
  .large_banner .content img:first-child {
    width: 55px;
  }
  
  .large_banner .content p {
    margin: 20px 10px;
    font-size: 14px;
    line-height: 18px;
  }
}