.bannerImage {
  background-image: url(../../../public/images/scanner_security_banner.png);
  background-position: center;
  background-size: cover;
}
.serviceBody {
  background-color: var(--black);
  border-radius: var(--radius);
  margin: 0 auto;
  margin-top: -80px;
}
.serviceBody .center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.scanners {
  width: 100%;
  display: flex;
  margin-top: 80px;
  padding-bottom: 40px;
  position: relative;
}
.scanners:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  background-color: #000;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  background: linear-gradient(0deg, rgba(255,255,255,0.03) 0%, rgba(0,0,0,1) 100%);
}

.scanners .item {
  margin: 0 50px;
  z-index: 2;
}

.scanners .image_container {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scanners .item h4,
.scanners .item p {
  text-align: center;
}
.scanners .item h4 {
  font-size: 28px;
  color: var(--blue);
  margin: 30px 0;
}
.scanners .item p {
  font-size: 16px;
  font-family: "AlbertSans-Regular";
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
}

.slider_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.slider_container h3 {
  font-size: 56px;
  color: var(--blue);
  font-family: "Mont-Bold";
  line-height: 96px;
}

.slider_container h5 {
  align-self: flex-start;
  font-family: "Mont-Bold";
  font-size: 32px;
  line-height: 54px;
  margin: 40px 0 30px 60px;
}

.arrow_left,
.arrow_right {
  position: absolute;
  top: 50%;
  z-index: 999;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  outline: none;
  border: none;
  color: var(--blue);
  font-size: 18px;
  line-height: 38px;
  text-align: center;
}
.arrow_left {
  left: -50px;
}
.arrow_right {
  right: -50px;
}

.points {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
}
.points li {
  width: 33.33%;
  list-style: disc;
  font-size: 24px;
  line-height: 30px;
}

.explore {
  font-size: 24px;
  color: var(--white);
  border-radius: 35px;
  padding: 18px 50px;
  background-color: var(--blue);
}

@media only screen and (max-width: 768px) {
  .serviceBody {
    margin-top: -60px;
  }
  .scanners {
    margin-top: 30px;
    padding-bottom: 20px;
  }
  .scanners .item {
    margin: 0;
    padding: 0 10px;
  }
  .serviceBody .center {
    margin-bottom: 10px;
  }
}