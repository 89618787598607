.banner {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  
}
.banner>div{
  border-bottom: 1px solid black;
}
.banner img.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.2);
}

.banner .information {
  display: flex;
  flex-direction: column;
  padding: 300px 0px 0px 0px;
}

.banner .information h3 {
  font-family: "Mont-Bold";
  font-size: 76px;
  text-align: center;
}
.banner .information .services {
  display: flex;
  align-items: center;
  margin: 40px -20px;
}
.banner .services div {
  width: 25%;
  margin: 0 20px;
  border-radius: 24px;
  border: 2px solid var(--blue);
  height: 130px;
  font-size: 30px;
  transition: 0.5s;
}
.banner .services .service {
  color: var(--white);
  display: flex;
  padding: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.banner .services div:hover {
  background-color: var(--blue);
}
.banner .banner_logo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  padding: 200px 0px 0px 0px;
}
.banner .banner_logo p {
  text-transform: uppercase;
}
.banner .banner_logo h2 {
  text-align: right;
}
.banner_logo div {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* //BLUE BANNER */
.blue {
  background: #00c6ff;
  background: -webkit-linear-gradient(to bottom, #0037f9, #00c6ff);
  background: linear-gradient(to bottom, #0037f9, #00c6ff);
  padding: 150px 0px;
  border-radius: var(--radius);
  margin-top: -80px;
  display: flex;
  overflow: hidden;
}
.blue .lines {
  position: relative;
}
.blue .lines::before {
  content: url("../../../public/images/lines/left.svg"); /* with class ModalCarrot ??*/
  position: absolute;
  width: 156%;
  top: -75%;
  left: 100%;
  left: -32%;
  z-index: 0;
}

.subBanner{
  background: #00c6ff;
  background: -webkit-linear-gradient(to bottom, #0037f9, #00c6ff);
  background: linear-gradient(to bottom, #0037f9, #00c6ff);
  padding: 180px 0px 120px 0px;
}

.subBanner>div{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.subBanner h1{
  width: 50%;
}
.subBannerText{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding-top: 100px;
  
}
.subBannerText div{
  background-color: var(--blue);  
  padding:20px;
  border-radius: var(--radius);
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* mobile */
@media only screen and (max-width: 1024px) {
  .blue {
    margin-top: 60px;
    padding: 40px 0 20px;
  }
  .blue .lines::before {
    top: 10%;
    left: -10%;
  }
  .banner .information {
    padding: 150px 0px 0px 0px;
  }
  .banner .information h3 {
    font-size: 38px;
  }
  .banner .banner_logo {
    display: none;
  }
  .banner .information .services {
    flex-direction: column;
    margin: 40px 0;
  }
  .banner .services div {
    width: 220px;
    height: unset;
    font-size: 16px;
    margin: 5px 0;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 768px) {
  

  .subBanner {
    padding: 100px 0px 60px 0px;
  }
  .subBanner h1 {
    width: 100%;
  }
  .subBannerText{
    grid-gap: 10px;
    padding-top: 40px;
    grid-template-columns: unset;
    justify-content: center;
  }
  .subBannerText div{
    width: 220px;
    font-size: 16px;
    padding: 14px;
    border-radius: 10px;
  }
}
/* mobile */