.serviceBanner{
  width: 100%;
  padding: 70px 0px;
  min-height: 50vh;
  margin: 150px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--radius);
}
.serviceBannerText{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding:100px 0px;
  height: 100%;
}
.serviceBannerText div{
  width: 50%;
}
.serviceBannerText div p{
  padding:20px 0px;
}

@media only screen and (max-width: 1240px) {
  .serviceBanner{
    min-height: 30vh;
    margin: 60px 0 60px;
  }
  .serviceBannerText{
    padding: 40px 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .serviceBanner {
    padding: 40px 0;
    margin: 60px 0 60px;
    background-position: center;
    border-radius: 0;
  }
  .serviceBanner h1 {
    text-align: center;
  }
  .serviceBannerText {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
  .serviceBannerText div{
    width: 100%;
  }
  .serviceBannerText div p{
    text-align: center;
  }
}
